<template>
  <div class="p-4">
    <div class="mb-4">Mis información personal</div>
    <BaseForm
      :formElements="formElements"
      @form:onSubmit="data => update(data)"
      :isLoading="isLoading"
    >
      <template #elements>
        <BaseFormElement
          :key="key"
          v-for="(element, key) in formElements"
          :elements="formElements"
          :element="element"
          v-model="element.data.value"
        />
      </template>
    </BaseForm>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import BaseForm from '@/components/form/BaseForm.vue'
import BaseFormElement from '@/components/form/BaseFormElement.vue'
import { formElements as AboutMeFormElements } from '@/config/AboutMeFormElements.ts'
import syncValues from '@/utils/syncValues.ts'

// About me store
import { ABOUT_ME_STORE_NAME } from '@/store/modules/aboutMe'
import { UPDATE_ABOUT_ME } from '@/store/modules/aboutMe/actions.type'

// Notifications store
import { NOTIFICATIONS_STORE_NAME } from '@/store/modules/notifications'
import { PUSH_NOTIFICATION } from '@/store/modules/notifications/actions.type'

import { useStore } from 'vuex'
export default {
  components: {
    BaseForm,
    BaseFormElement
  },
  setup() {
    const store = useStore()
    const formElements = ref(AboutMeFormElements)
    const bannerData = computed(
      () => store.getters[ABOUT_ME_STORE_NAME + '/data']
    )
    const isLoading = computed(
      () => store.getters[ABOUT_ME_STORE_NAME + '/isLoading']
    )
    const isError = computed(
      () => store.getters[ABOUT_ME_STORE_NAME + '/isError']
    )
    const isReady = computed(
      () => store.getters[ABOUT_ME_STORE_NAME + '/isReady']
    )

    watch(bannerData, bannerData => {
      if (bannerData) {
        formElements.value = syncValues(formElements.value, bannerData)
      }
    })
    if (bannerData.value) {
      formElements.value = syncValues(formElements.value, bannerData.value)
    }
    async function update(data) {
      await store.dispatch(ABOUT_ME_STORE_NAME + '/' + UPDATE_ABOUT_ME, data)
      if (isReady.value) {
        pushNotification({
          title: 'Aplicado correctamente!',
          desciption: 'Tu informacion Acerca de ti se ha actualizado ',
          type: 'success'
        })
      }
      if (isError.value) {
        pushNotification({
          title: 'Algun error ha ocurrido!',
          desciption: 'Vuelve a intentarlo o contacta a soporte',
          type: 'error'
        })
      }
    }

    function pushNotification(data) {
      store.dispatch(NOTIFICATIONS_STORE_NAME + '/' + PUSH_NOTIFICATION, data)
    }

    return {
      isLoading,
      update,
      formElements
    }
  }
}
</script>
