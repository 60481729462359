import Input from '@/interfaces/inputs/Input'
import Datalist from '@/interfaces/inputs/Datalist'
import FormElement from '@/interfaces/form/FormElement'
import Textarea from '@/interfaces/inputs/Textarea'

const country: Datalist = {
  id: 'country',
  label: 'Pais',
  value: null,
  options: [
    {
      value: 'es',
      text: 'España'
    },
    {
      value: 'pt',
      text: 'Portugal'
    }
  ]
}

const photo: Input = {
  id: 'photo',
  label: 'Imagen de perfil',
  type: 'file',
  value: null
}

const name: Input = {
  id: 'name',
  label: 'Nombre',
  type: 'text',
  value: null,
  required: true
}

const lastNames: Input = {
  id: 'lastNames',
  label: 'Apellidos',
  type: 'text',
  value: null,
  required: true
}

const dateOfBirth: Input = {
  id: 'dateOfBirth',
  label: 'Fecha de nacimieto',
  type: 'date',
  value: null,
  required: true
}

const address: Input = {
  id: 'address',
  label: 'Dirección',
  type: 'text',
  value: null,
  required: true
}

const phone: Input = {
  id: 'phone',
  label: 'Teléfono',
  type: 'tel',
  value: null,
  required: true
}

const biography: Textarea = {
  id: 'biography',
  label: 'Biografia',
  value: null,
  required: true
}

export const formElements: FormElement[] = [
  {
    type: 'input',
    data: photo
  },
  {
    type: 'datalist',
    data: country
  },
  {
    type: 'input',
    data: name
  },
  {
    type: 'input',
    data: lastNames
  },
  {
    type: 'input',
    data: dateOfBirth
  },
  {
    type: 'input',
    data: address
  },
  {
    type: 'input',
    data: phone
  },
  {
    type: 'textarea',
    data: biography
  }
]
